import React from 'react';
import {
    Container,
    ButtonText,
    ContentContainer,
    Button,
    Title,
    SubTitle,
    Logo,
} from './TermsAndConditionsElements';

import { termsText } from '../../assets/data';

const Footer = () => {
    const onPressGoBack = () => {
        if (window !== 'undefined') {
            window.opener = null;
            window.open('', '_self');
            window.close();
        }
    };

    return (
        <Container>
            <Logo to="/" />
            <ContentContainer>
                <Title>{termsText.title}</Title>
                <SubTitle>
                    Este contrato describe los términos y condiciones generales (los "Términos y
                    Condiciones Generales") aplicables a la descarga, acceso, utilización de la
                    aplicación móvil y el website www.oasisrecircula.org.ar ofrecidos por Tomas
                    Corellano, CUIT 23-32603481-9, que pone a disposición de los vecinos del barrio
                    “River Side”, ubicado en Río Cuarto, Córdoba en su etapa de prueba piloto.
                    <br />
                    CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS
                    CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE
                    UTILIZAR EL SITIO Y/O LA APLICACIÓN MOVIL.
                    <br />
                    El Usuario debe leer, entender y aceptar todas las condiciones establecidas en
                    los Términos y Condiciones Generales y en las Políticas de Privacidad así como
                    en los demás documentos incorporados a los mismos por referencia, previo a su
                    registración como Usuario de Oasis Recircula, optando de forma expresa por
                    recibir los mismos y toda otra información por medios digitales.
                    <br />
                    El acceso a la APLICACIÓN supone que el usuario reconoce ha aceptado y
                    consentido sin reservas de las presentes condiciones de uso.
                    <br />
                    <br />
                    1. OBJETO
                    <br />
                    La APLICACIÓN tiene el objetivo de incentivar a los usuarios a realizar acciones
                    de reciclaje y separación de residuos mediante la recompensa de tales acciones.
                    La APLICACIÓN se pone a disposición de los usuarios para su uso personal (nunca
                    empresarial), y con el único fin de realizar una prueba piloto.
                    <br />
                    <br />
                    2.- PROPIEDAD INTELECTUAL
                    <br />
                    Los derechos de propiedad intelectual e industrial sobre la APLICACIÓN son
                    titularidad de Tomas Corellano que autoriza su utilización a los usuarios a su
                    utilización en la etapa de prueba piloto, correspondiéndole el ejercicio
                    exclusivo de los derechos de explotación de los mismos en cualquier forma y, en
                    especial, los derechos de reproducción, distribución, comunicación pública y
                    transformación. Asimismo Tomas Corellano es titular de derechos de propiedad
                    intelectual e industrial sobre fotografías, logotipos, y cualesquiera otros
                    símbolos o contenidos incluidos en la APLICACIÓN y el WEBSITE. El usuario
                    reconoce que la reproducción, modificación, distribución, comercialización,
                    descompilación, desensamblado, utilización de técnicas de ingeniería inversa o
                    de cualquier otro medio para obtener el código fuente, transformación o
                    publicación de cualquier resultado de pruebas de referencias no autorizadas de
                    cualquiera de los elementos y utilidades integradas dentro del desarrollo
                    constituye una infracción de los derechos de propiedad intelectual hacia los
                    titulares, obligándose, en consecuencia, a no realizar ninguna de las acciones
                    mencionadas.
                    <br />
                    <br />
                    3.- USO DE DATOS
                    <br />
                    La APLICACIÓN podrá utilizar la información de carácter personal que nos
                    facilite de forma disociada para fines internos, tales como la elaboración de
                    estadísticas. La APLICACIÓN podrá recabar, almacenar o acumular determinada
                    información referente a su uso. Dado que el presente Contrato implica el
                    tratamiento de datos personales por parte de la APLICACION, esta queda obligada
                    al cumplimiento de la Ley Nº 25.326 y demás normativa aplicable (la “LPDP”).
                    <br />
                    <br />
                    4.- MODIFICACIONES AL ACUERCO
                    <br />
                    La APLICACIÓN se reserva la facultad de efectuar, en cualquier momento y sin
                    necesidad de previo aviso, modificaciones y actualizaciones. Asimismo, también
                    se reserva el derecho a modificar los presentes Términos y Condiciones en
                    cualquier momento haciendo públicos en el Sitio los términos modificados con el
                    objetivo de adaptarlos a las posibles novedades legislativas y cambios en la
                    propia APLICACIÓN, así como a las que se puedan derivar de los códigos tipos
                    existentes en la materia o por motivos estratégicos o corporativos. Todos los
                    términos modificados entrarán en vigor a los 10 (diez) días de su publicación en
                    el sitio. Todo usuario que no esté de acuerdo con las modificaciones efectuadas
                    por podrá solicitar la baja de la cuenta.
                    <br />
                    <br />
                    5.- DERECHO DE REALIZAR MODIFICACIONES
                    <br />
                    La APLICACIÓN se reserva el derecho de editar, actualizar, modificar, suspender,
                    eliminar o finalizar los servicios ofrecidos por la APLICACIÓN, incluyendo todo
                    o parte de su contenido, sin necesidad de previo aviso, así como de modificar la
                    forma o tipo de acceso a esta. Las posibles causas de modificación pueden tener
                    lugar, por motivos tales, como su adaptación a las posibles novedades
                    legislativas y cambios en la propia APLICACION, así como a las que se puedan
                    derivar de los códigos tipos existentes en la materia o por motivos estratégicos
                    o corporativos.
                    <br />
                    <br />
                    6.- RESPONSABILIDAD POR EL USO
                    <br />
                    La responsabilidad de uso de la APLICACIÓN corresponde solo al usuario. Salvo lo
                    establecido en estos Términos y Condiciones, ni los titulares, ni Tomas
                    Corellano son responsables de ninguna pérdida o daño que se produzca en relación
                    con la descarga o el uso de la APLICACIÓN, tales como los producidos como
                    consecuencia de fallos, averías o bloqueos en el funcionamiento de la APLICACIÓN
                    (por ejemplo, y sin carácter limitativo: error en las líneas de comunicaciones,
                    defectos en el hardware o software de la APLICACIÓN o fallos en la red de
                    Internet). Igualmente, ni el titular, serán responsables de los daños producidos
                    como consecuencia de un uso indebido o inadecuado de la APLICACIÓN por parte de
                    los usuarios.
                    <br />
                    <br />
                    8.- COMPETENCIA
                    <br />
                    El usuario acepta que la legislación aplicable y los Juzgados y Tribunales
                    competentes para conocer de las divergencias derivadas de la interpretación o
                    aplicación de la APLICACION, y se somete a la jurisdicción de los Tribunales
                    Ordinarios de la Ciudad de Córdoba Capital, Provincia de Córdoba renunciando
                    expresamente a otro fuero o jurisdicción.
                </SubTitle>
                <Button onClick={() => onPressGoBack()}>
                    <ButtonText>Volver</ButtonText>
                </Button>
            </ContentContainer>
        </Container>
    );
};

export default Footer;
