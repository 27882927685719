import styled from 'styled-components';
import logoImage from '../../assets/images/imagotipo.png';
import { Link } from 'gatsby';

export const Container = styled.div`
    background: linear-gradient(303.52deg, #a8cf45 26.23%, #00afef 77.92%);
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden; //horizontal
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20vw;
    width: auto;
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        padding-left: 10vw;
    }
    @media screen and (max-width: 4000px) {
        padding-top: 50px;
    }
`;

export const Button = styled.button`
    border: 1px solid #ffffff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 163px;
    height: 29px;
    cursor: pointer;
    margin-bottom: 60px;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    background-color: transparent;
    :hover {
        opacity: ${({ disabled }) => (disabled ? 0.8 : 0.5)};
    }
`;

export const ButtonText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 14px;
    color: #fafafa;
`;

export const Title = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: #faf9f9;
    margin-bottom: 14px;
    @media screen and (max-width: 400px) {
        font-size: 20px;
        line-height: 23px;
    }
`;

export const SubTitle = styled.div`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #faf9f9;
    width: 590px;
    margin-bottom: 30px;

    @media screen and (max-width: 1000px) {
        width: 60vw;
        font-size: 14px;
        line-height: 22px;
    }
    @media screen and (max-width: 700px) {
        width: 80vw;
    }
    @media screen and (max-width: 400px) {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 15px;
    }
`;

export const Logo = styled(Link)`
    width: 153px;
    height: 46px;
    background: url(${logoImage}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-right: auto;
    display: flex;
    margin-left: 48px;
    margin-top: 16px;
`;
